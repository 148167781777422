<template>
  <div class="email-notifications">
    <div class="email-notifications__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <div class="email-notifications-container" v-loading="loadingTrackingPreferences">
      <div class="card">
        <div class="card-header">
          <p>
            <span
              >To ensure we align with your preferences, please select how you would like to receive
              our updates during transit of your cargo. This questionnaire can be updated anytime
              your preferences change.</span
            >
          </p>
        </div>
        <div class="card-body">
          <div class="form-item">
            <label for="pickup-confirmation">1. Pick-Up Confirmation with Pro Number</label>
            <el-select
              :class="validationErrors.tracking_pickup_confirmation ? 'missing-field' : ''"
              v-model="trackingPreferences.tracking_pickup_confirmation"
              placeholder="Select an option"
            >
              <el-option label="Yes" value="Yes"></el-option>
              <el-option label="No" value="No"></el-option>
            </el-select>
          </div>

          <div class="form-item">
            <label for="in-transit-updates">2. In-Transit Updates</label>
            <el-select
              :class="validationErrors.tracking_in_transit_updates ? 'missing-field' : ''"
              v-model="trackingPreferences.tracking_in_transit_updates"
              placeholder="Select an option"
            >
              <el-option label="All Regular updates" value="All Regular updates"></el-option>
              <el-option
                label="Update when out for delivery"
                value="Update when out for delivery"
              ></el-option>
              <el-option
                label="Updates only for issues or extra charges"
                value="Updates only for issues or extra charges"
              ></el-option>
              <el-option label="No updates" value="No updates"></el-option>
            </el-select>
          </div>

          <div class="form-item">
            <label for="delivery-notice">3. Delivery Notice (POD)</label>
            <el-select
              :class="validationErrors.tracking_delivery_notice ? 'missing-field' : ''"
              v-model="trackingPreferences.tracking_delivery_notice"
              placeholder="Select an option"
            >
              <el-option label="Yes" value="Yes"></el-option>
              <el-option label="No" value="No"></el-option>
            </el-select>
          </div>
          <div class="filters-footer">
            <button @click="assignTrackingPreferences()" class="save-button">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailNotifications",
  components: {},
  data() {
    return {
      initialPreferences: {},
      trackingPreferences: {
        tracking_pickup_confirmation: "",
        tracking_in_transit_updates: "",
        tracking_delivery_notice: "",
      },
      loadingTrackingPreferences: true,
      payload: {},
      validationErrors: {
        tracking_pickup_confirmation: false,
        tracking_in_transit_updates: false,
        tracking_delivery_notice: false,
      },
    };
  },
  beforeMount() {
    this.getInitialTrackingPreferences();
  },
  created() {},
  methods: {
    validateFields() {
      this.validationErrors = {
        tracking_pickup_confirmation: false,
        tracking_in_transit_updates: false,
        tracking_delivery_notice: false,
      };
      const hasChanges =
        this.trackingPreferences.tracking_pickup_confirmation !==
          this.initialPreferences.tracking_pickup_confirmation ||
        this.trackingPreferences.tracking_in_transit_updates !==
          this.initialPreferences.tracking_in_transit_updates ||
        this.trackingPreferences.tracking_delivery_notice !==
          this.initialPreferences.tracking_delivery_notice;
      if (hasChanges) {
        let isValid = true;
        Object.keys(this.trackingPreferences).forEach((key) => {
          if (!this.trackingPreferences[key]) {
            this.validationErrors[key] = true;
            isValid = false;
          }
        });
        return isValid;
      }
      return true;
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
    async getInitialTrackingPreferences() {
      const response = await this.$store.dispatch("account/getTrackingPreferences");
      this.trackingPreferences.tracking_pickup_confirmation =
        response.tracking_pickup_confirmation || "";
      this.trackingPreferences.tracking_in_transit_updates =
        response.tracking_in_transit_updates || "";
      this.trackingPreferences.tracking_delivery_notice = response.tracking_delivery_notice || "";
      this.initialPreferences = {
        tracking_pickup_confirmation: this.trackingPreferences.tracking_pickup_confirmation,
        tracking_in_transit_updates: this.trackingPreferences.tracking_in_transit_updates,
        tracking_delivery_notice: this.trackingPreferences.tracking_delivery_notice,
      };
      this.loadingTrackingPreferences = false;
    },
    validateFieldsTrakingPreferences() {
      if (
        this.trackingPreferences.tracking_pickup_confirmation !==
        this.initialPreferences.tracking_pickup_confirmation
      ) {
        this.payload.tracking_pickup_confirmation =
          this.trackingPreferences.tracking_pickup_confirmation;
      }
      if (
        this.trackingPreferences.tracking_in_transit_updates !==
        this.initialPreferences.tracking_in_transit_updates
      ) {
        this.payload.tracking_in_transit_updates =
          this.trackingPreferences.tracking_in_transit_updates;
      }
      if (
        this.trackingPreferences.tracking_delivery_notice !==
        this.initialPreferences.tracking_delivery_notice
      ) {
        this.payload.tracking_delivery_notice = this.trackingPreferences.tracking_delivery_notice;
      }
    },
    async assignTrackingPreferences() {
      if (!this.validateFields()) {
        this.$message({
          type: "error",
          message: "Please complete all fields before saving.",
          duration: 4000,
        });
        return;
      }
      this.validateFieldsTrakingPreferences();
      if (Object.keys(this.payload).length > 0) {
        this.saveTrackingPreferences();
      } else {
        this.$message({
          type: "info",
          message: "No changes detected in your tracking preferences.",
          duration: 4000,
        });
      }
    },
    async saveTrackingPreferences() {
      this.loadingTrackingPreferences = true;
      await this.$store.dispatch("account/updateTrackingPreferences", this.payload);
      this.$store.commit("account/setTrackingPreferences", this.trackingPreferences);
      this.initialPreferences = { ...this.trackingPreferences };
      this.payload = {};
      this.loadingTrackingPreferences = false;
      this.$message({
        type: "success",
        message: "Tracking preferences updated successfully. The changes were loaded correctly.",
        duration: 4000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/Modal.scss";

.email-notifications {
  display: flex;
  flex-direction: column;
  font-family: $font-family-portal;
  padding: 2%;
  @media (max-width: 800px) {
    width: 97%;
    margin: auto;
    padding: 2% 0;
  }
  &__header {
    display: flex;
    padding: 1%;
    background-color: #efefef;
    border: 2px solid $color-border-container;
    border-radius: 15px;
    font-weight: bold;
    font-size: 0.875rem;
    &-name {
      width: 25%;
      border-right: 2px solid $color-border-container;
    }
    &-description {
      width: 65%;
      border-right: 2px solid $color-border-container;
    }
    &-edit {
      width: 10%;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}
.email-notifications-container {
  display: flex;
  justify-content: space-around;
}
/* card styles */
.card {
  width: 700px;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: white;
}

.card-body {
  font-size: 1em;
}

::v-deep .missing-field input {
  background: $missing-fields;
}

.form-item {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}
.select-component {
  background-color: red;
}
/* Button */
.save-button {
  margin: 25px auto 0px auto;
  display: flex;
  justify-content: space-around;
  @include secondary-button;
  background-color: $color-save-button;
  color: $color-white;
  border: none;

  &:hover {
    background-color: $color-primary-trigger-button-hover;
  }

  width: 20%;
  text-align: center;
  padding: 7px;
}

::v-deep .el-input__inner {
  border-radius: 10px;
  border: 1px solid $color-border-container;
  width: 55vh;
  @media (max-width: 800px) {
    width: 45vh;
  }
  @media (max-width: 400px) {
    width: 25vh;
  }
}
</style>
